<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <v-container>
          <br />

          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                @click.prevent="addRecordPrompt = true"
                color="#8950FC"
                elevation="30"
                shaped
                large
                class="font-size-h6 mr-3 my-3 white--text"
              >
                &nbsp; Create &nbsp;
              </v-btn>
              <v-btn
                large
                @click.prevent="refreshPageData"
                color="#a4c639"
                elevation="30"
                shaped
                class="font-size-h6 mr-3 my-3 white--text"
                >&nbsp; Refresh &nbsp;
              </v-btn>
            </v-col>
          </v-row>

          <v-container class="py-0" v-if="ErrorMessageTxtFlag == 1">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">{{ ErrorMessageTxt }}</h3>
              </v-col>
            </v-row>
          </v-container>

          <v-dialog v-model="addRecordPrompt" max-width="85%">
            <jcom-table-create
              :addRecordPrompt="addRecordPrompt"
              @hideAddRecordPrompt="hideAddRecordPrompt($event)"
              :recordIndex="recordToEdit"
              v-if="addRecordPrompt"
            ></jcom-table-create>
          </v-dialog>

          <v-dialog v-model="editRecordPrompt" max-width="85%">
            <jcom-table-edit
              :editRecordPrompt="editRecordPrompt"
              @hideEditRecordPrompt="hideEditRecordPrompt($event)"
              :recordData="recordData"
              v-if="editRecordPrompt"
            ></jcom-table-edit>
          </v-dialog>

          <v-dialog v-model="previewRecordPrompt" max-width="75%">
            <jcom-table-preview
              :previewRecordPrompt="previewRecordPrompt"
              @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
              :recordData="recordData"
              v-if="previewRecordPrompt"
            ></jcom-table-preview>
          </v-dialog>

          <v-container class="py-0">
            <v-form ref="form" v-model="valid" lazy-validation id="createForm">
              <v-row wrap>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <h6>Zone <small> (Optional)</small></h6>
                  <v-autocomplete
                    @change="getTableTypeOptions"
                    :reduce="(option) => option.value"
                    :items="ZoneCodeOptions"
                    :rules="ZoneCodeRules"
                    v-model="ZoneCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <h6>Table Type <small> (Optional)</small></h6>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="TableTypeOptions"
                    :rules="TableTypeRules"
                    v-model="TableType"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    class="btn btn-primary font-size-h6 py-4 my-3 mr-3 white--text"
                    @click="searchForm"
                  >
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <v-container class="py-0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <div
                  class="d-flex justify-content-center mb-3"
                  v-if="ProgessStart == 1"
                >
                  <b-spinner
                    variant="primary"
                    type="grow"
                    label="We are fetching details, please wait..."
                  ></b-spinner>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0" v-if="tableData1.length > 0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">
                  {{ tableData1.length }} records found.
                </h3>
                <v-btn
                  v-if="DownloadFlag"
                  color="#8a2be2"
                  @click.prevent="
                    generateExcel(
                      tableData1,
                      ExcelFields,
                      ExcelFileName,
                      'My Worksheet'
                    )
                  "
                  class="btn btn-primary font-size-h6 py-4 my-3 mr-3 white--text"
                  >Download</v-btn
                >
              </v-col>
              <p></p>
            </v-row>
            <br />
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  class="elevation-1"
                  v-model="selected1"
                  :search="search"
                  :item-key="tableOptions1.ItemKey"
                  :single-select="tableOptions1.SingleSelectFlag"
                  :show-select="tableOptions1.ShowSelectFlag"
                  :headers="tableColumns1"
                  :items="tableData1"
                  :items-per-page="tableOptions1.ItemsPerPage"
                  :footer-props="tableOptions1.FooterProps"
                >
                  <template v-slot:item.ActiveStatusTxt="{ item }">
                    <v-chip
                      :color="item.ActiveStatusColor"
                      draggable
                      dark
                      >{{ item.ActiveStatusTxt }}</v-chip
                    >
                  </template>
                  <template v-slot:item.MemberImagePath="{ item }">
                    <img width="100" height="100" :src="item.MemberImagePath" />
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom v-if="item.PreviewFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="primary"
                          @click="previewAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-search
                        </v-icon>
                      </template>
                      <span> Preview </span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.EditFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="warning"
                          @click="editAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-document-edit
                        </v-icon>
                      </template>
                      <span> Edit </span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.DeleteFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="#ff0000"
                          @click="deleteAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span> Edit </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>

          <br />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import JcomTableCreate from "@/view/pages/erp/jcom/jcom-table/JcomTableCreate.vue";
import JcomTableEdit from "@/view/pages/erp/jcom/jcom-table/JcomTableEdit.vue";
import JcomTablePreview from "@/view/pages/erp/jcom/jcom-table/JcomTablePreview.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    JcomTableCreate,
    JcomTableEdit,
    JcomTablePreview,
  },
  data() {
    return {
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      ZipDownloadFlag: false,
      ZipFileName: "",

      SelectFlag: true,
      DeleteFlag: false,

      alert: {},

      valid: true,

      JciYearCodeFlag: false,
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: 12,
      JciYearCodeOptions: [],

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],

      TableTypeRules: [],
      TableType: "",
      TableTypeOptions: [],

      RoleId: "",

      ResultFlag: 0,

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      recordData: {},

      PaymentPaidDate: "",
      menu2: false,

      dialog: false,
      dialogDelete: false,
      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],
      TotalMembers: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.getZoneCodeOptions();
        this.getTableTypeOptions();
      }
    },
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 = rows.length;
      var flag = n1 == 0 ? 0 : 1;
      console.log("n1=" + n1 + ", flag=" + flag);
      this.ResultFlag = flag;
      if (n1 > 0) {
        var filter1 = {
          ActiveStatusTxt: "Active",
        };
        console.log("filter1=" + JSON.stringify(filter1));
        var records1 = rows;
        records1 = records1.filter(function (item) {
          for (var key in filter1) {
            if (item[key] === undefined || item[key] != filter1[key])
              return false;
          }
          return true;
        });
        var n2 = records1.length;
        console.log("n2=" + n2 + ", records1=" + JSON.stringify(records1));

        var filter2 = {
          ActiveStatusTxt: "Pending",
        };
        console.log("filter2=" + JSON.stringify(filter2));
        var records2 = rows;
        records2 = records2.filter(function (item) {
          for (var key in filter2) {
            if (item[key] === undefined || item[key] != filter2[key])
              return false;
          }
          return true;
        });
        var n3 = records2.length;
        console.log("n3=" + n3 + ", records2=" + JSON.stringify(records2));

        this.totalActiveAwards = n2;
        this.totalPendingAwards = n3;
      }
    },
    IdCardType: function () {
      console.log("watch IdCardType");
      var IdCardType = this.IdCardType;
      console.log({ IdCardType });
      this.tableData1 = [];
      this.selected1 = [];
      this.DownloadMemberPhotos = 0;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    rowSelected(tr) {
      console.log("rowSelected called");
      console.log("tr=" + JSON.stringify(tr));
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.searchForm();
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
      this.totalActiveAwards = 0;
      this.totalPendingAwards = 0;
    },
    previewAlert(tr) {
      console.log("previewAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.recordData = tr;
        this.previewRecordPrompt = true;
      } else {
        var message = "Kindly select one record to preview";
        this.errorMessage(message);
      }
    },
    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.searchForm();
      }
    },
    editAlert(tr) {
      console.log("editAlert called");
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.recordData = tr;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    addNewRow(tableId) {
      console.log("addNewRow called");
      console.log("tableId=" + tableId);
      if (tableId == 1) {
        var n1 = this.AllDesignation.length;
        console.log("n1=" + n1);
        if (n1 < 5) {
          this.AllDesignation.push({
            year: "",
            level: "",
            position: "",
          });
        }
      }

      if (tableId == 2) {
        var n1 = this.NewMembers.length;
        console.log("n1=" + n1);
        if (n1 < 30) {
          this.NewMembers.push({
            MemberName: "",
            MemberEmail: "",
            MemberMobileNo: "",
            MemberAddress: "",
            MemberCity: "",
          });
        }
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log(
        "tableId=" + tableId + ", index=" + index + ", tr=" + JSON.stringify(tr)
      );
      if (index > -1) {
        if (tableId == 1) {
          this.AllDesignation.splice(index, 1);
        }
        if (tableId == 2) {
          this.NewMembers.splice(index, 1);
        }
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_table",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        LomCode: 3,
        ZoneCode: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getFormFieldOptions() {
      console.log("getFormFieldOptions called");
      // this.getContributionTypeIdOptions();
      this.getZoneCodeOptions();
    },
    getContributionTypeIdOptions() {
      console.log("getContributionTypeIdOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ContributionTypeId";
        var selectbox1_destination = "ContributionTypeIdOptions";
        var selectbox1_url = "api/year-wise-contribution/type-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Jci Year should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/jcom/table/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getTableTypeOptions() {
      console.log("getTableTypeOptions called");
      var selectbox1_source = "TableType";
      var selectbox1_destination = "TableTypeOptions";
      var selectbox1_url = "api/jcom/table-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm called");

      this.resetMessageTxt();

      var ZoneCode = this.ZoneCode;
      var TableType = this.TableType;
      console.log("ZoneCode=" + ZoneCode + ", TableType=" + TableType);

      var result = this.$refs.form.validate();
      console.log("result=" + result);

      if (result) {
        this.DownloadFlag = false;
        this.ExcelFileName = "";
        this.ZipDownloadFlag = false;
        this.ZipFileName = "";
        this.tableData1 = [];
        this.selected1 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/table/lists";
        var upload = {
          UserInterface: 1,
          Zone: ZoneCode,
          TableType: TableType,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = [];
        var table_content = [];
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            table_content = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var options = table_content.TableOptions;
            records = table_content.TableData;

            if (flag == 1) {
              thisIns.tableColumns1 = table_content.TableHeader;
              thisIns.tableOptions1 = options;
              thisIns.tableData1 = records;

              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;
              thisIns.ZipDownloadFlag = options.ZipDownloadFlag;
              thisIns.ZipFileName = options.ZipFileName;

              thisIns.toast("success", output);
            } else {
              console.log("error=" + output);
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
          });
      } else {
        var message = "Kindy fill required fields and then submit";
        this.toast("error", message);
      }
    },
    updateConfirmAlert() {
      this.resetMessageTxt();

      var selected = this.selected1;
      var n1 = selected.length;
      console.log("n1=" + n1 + ", selected=" + JSON.stringify(selected));

      var result = this.$refs.form.validate();
      console.log("result=" + result);

      if (n1 > 0 && result) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            var upload = {
              Year: this.JciYearCode,
              Members: selected,
            };
            this.updateRecords(upload);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!result) {
          message += "Kindly fill the required fields. ";
        }
        if (n1 == 0) {
          message += "Atleast select one member to update. ";
        }
        this.toast("error", message);
      }
    },
    updateRecords(upload) {
      console.log("updateRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/reports/issued-cards-update";
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      // thisIns.pageLoadingOn()
      thisIns.ProgessStart = 1;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.ProgessStart = 0;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.tableData1 = [];
            thisIns.searchForm();
            thisIns.sweetAlert("success", output, true);
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log("tr=" + JSON.stringify(tr));
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.confirmAlert();
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/>Zone <b> " + tr.ZoneName + "</b>";
        htmlTxt += "<br/>Table Type <b> " + tr.TableTypeName + "</b>";
        htmlTxt += "<br/>Table Name <b> " + tr.JcomTableName + "</b>";
        htmlTxt += "<br/><br/>Do you want to delete the data?";

        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRecord(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteRecord(tr) {
      console.log("deleteRecord called");

      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });

      if (n1 > 0) {
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/table/destroy";
        var upload = {
          UserInterface: 1,
          Zone: tr.ZoneId,
          Table: tr.JcomTableId,
          TableType: tr.TableTypeId,
          TableName: tr.JcomTableName,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "Kindly select one member to delete";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss">
</style>
